$(document).ready(function(){


    $('.tap-carousel').owlCarousel({
        loop:true,
        autoplay: true,
        autoplayTimeout:2000,
        toggle: true,
        autoplayHoverPause:true,
        paginationSpeed: 2500,
        margin:10,
        responsiveClass:true,
        items:1,
        smartSpeed: 1000,
    });


});